import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const NotFoundPage = () => (
  <Container style={{ paddingTop: '25vh' }}>
    <Row>
      <Col xs={12} className="text-center">
        <h1>404: NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Col>
    </Row>
  </Container>
)

export default NotFoundPage
